import { Base } from '@studiometa/js-toolkit';
import type { BaseConfig, BaseProps } from '@studiometa/js-toolkit';
import { nextFrame } from '@studiometa/js-toolkit/utils';

export interface SignatureProps extends BaseProps {
  $refs: {
    overlay: HTMLElement;
    content: HTMLElement;
  };
}

/**
 * Signature class.
 */
export default class Signature extends Base<SignatureProps> {
  /**
   * Config.
   */
  static config: BaseConfig = {
    name: 'Signature',
    refs: ['overlay', 'content'],
  };

  /**
   * Focus the contnent on click.
   */
  onOverlayClick() {
    this.$refs.content.focus();
  }

  /**
   * Select the content on focus.
   */
  onContentFocus() {
    this.selectAll();
  }

  /**
   * Select the content.
   */
  selectAll() {
    nextFrame(() => {
      document.execCommand('selectAll', false, null);
    });
  }
}
