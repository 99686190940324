import { Base } from '@studiometa/js-toolkit';
import type { BaseConfig, BaseProps } from '@studiometa/js-toolkit';

export interface SearchProps extends BaseProps {
  $el: HTMLInputElement;
}

/**
 * Search class.
 */
export default class Search extends Base<SearchProps> {
  /**
   * Config.
   */
  static config: BaseConfig = {
    name: 'Search',
  };

  /**
   * Get the current search value.
   */
  get value() {
    return this.$el.value;
  }

  /**
   * Trigger the search on input.
   */
  onInput() {
    this.search();
  }

  /**
   * Execute the search and filter SearchItem instances.
   */
  search() {
    this.$root.$children.SearchItem.forEach((item) => {
      if (this.fuzzy(this.value, item.$options.match)) {
        item.show();
      } else {
        item.hide();
      }
    });
  }

  /**
   * Performan a fuzzy search from a search query on the given string.
   */
  fuzzy(query: string, string: string, ratio = 0.75) {
    const lowerCaseString = string.toLowerCase();
    const lowerCaseQuery = query.toLowerCase();
    let matches = 0;

    if (lowerCaseString.includes(lowerCaseQuery)) {
      return true;
    }

    for (const letter of lowerCaseQuery) {
      matches = lowerCaseString.includes(letter) ? matches + 1 : matches - 1;
    }

    return matches / lowerCaseString.length >= ratio || lowerCaseQuery === '';
  }
}
