import { Base } from '@studiometa/js-toolkit';
import type { BaseConfig, BaseProps } from '@studiometa/js-toolkit';

export interface SearchItemProps extends BaseProps {
  $options: {
    match: string;
  };
}

/**
 * SearchItem class.
 */
export default class SearchItem extends Base<SearchItemProps> {
  /**
   * Config.
   */
  static config: BaseConfig = {
    name: 'SearchItem',
    options: {
      match: String,
    },
  };

  /**
   * Show the item.
   */
  show() {
    this.$el.style.display = '';
  }

  /**
   * Hide the item.
   */
  hide() {
    this.$el.style.display = 'none';
  }
}
