import { Base, createApp } from '@studiometa/js-toolkit';
import type { BaseConfig, BaseProps } from '@studiometa/js-toolkit';
import Search from './components/Search.js';
import SearchItem from './components/SearchItem.js';
import Signature from './components/Signature.js';

export interface AppProps extends BaseProps {
  $children: {
    Search: Search[];
    SearchItem: SearchItem[];
    Signature: Signature[];
  };
}

/**
 * App class.
 */
class App extends Base<AppProps> {
  /**
   * Config.
   */
  static config: BaseConfig = {
    name: 'App',
    components: {
      Search,
      SearchItem,
      Signature,
    },
  };
}

export default createApp(App, {
  features: {
    asyncChildren: true,
  },
});
